import { INPSUpload, INPSUploadDTO, IPlanilhaDadosVencimentos, IPlanilhaSaldoEmConta, IXPInfosSaldoEmConta, IXPInfosVencimentos } from "../Interface/uploadsXPFiles";
import { AUTH_HEADER } from "./Helpers/Headers";

export default class XPInfosUploadsService {
    private readonly API_URL = process.env.REACT_APP_API_URL;

    public async AddXPInfosVencimentos(data: IPlanilhaDadosVencimentos[]) {
        const url = `${this.API_URL}/XPInfosVencimentos/inserir`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: AUTH_HEADER(),
                body: JSON.stringify(data),
            });

            if (response.ok) {
                return await response.json();
            }

            // Tratamento de erros para status >= 400
            const errorResponse = await response.json();
            throw new Error(JSON.stringify(errorResponse));
        } catch (error) {
            throw new Error(`Falha ao inserir XPInfosVencimentos: ${(error as Error).message}`);
        }
    }

    public async GetXPInfosByAssessorId(assessorId: string): Promise<IXPInfosVencimentos[]> {
        const url = `${this.API_URL}/XPInfosVencimentos/listar-por-assessor/${assessorId}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: AUTH_HEADER(),
            });

            if (response.ok) {
                return await response.json();
            }

            // Tratamento de erros para status >= 400
            const errorResponse = await response.json();
            throw new Error(JSON.stringify(errorResponse));
        } catch (error) {
            throw new Error(`Falha ao obter XPInfosVencimentos: ${(error as Error).message}`);
        }
    }

    public async AddXPInfosSaldoEmConta(data: IPlanilhaSaldoEmConta[]) {
        const url = `${this.API_URL}/XPInfosSaldoEmConta/inserir`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: AUTH_HEADER(),
                body: JSON.stringify(data),
            });

            if (response.ok) {
                return await response.json();
            }

            // Tratamento de erros para status >= 400
            const errorResponse = await response.json();
            throw new Error(JSON.stringify(errorResponse));
        } catch (error) {
            throw new Error(`Falha ao inserir XPInfosSaldoEmConta: ${(error as Error).message}`);
        }
    }

    public async GetXPInfosSaldoEmContaByAssessorId(assessorId: string): Promise<IXPInfosSaldoEmConta[]> {
        const url = `${this.API_URL}/XPInfosSaldoEmConta/listar-por-assessor/${assessorId}`;
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: AUTH_HEADER(),
            });

            if (!response.ok) {
                throw new Error(`Erro ao obter Saldo em Conta. Status: ${response.status}`);
            }
            return await response.json();
        } catch (error: any) {
            throw new Error(error.message || 'Erro ao obter Saldo em Conta.');
        }
    }

    public async AddXPInfosNPSUploads(data: INPSUploadDTO[]): Promise<any> {
        const url = `${this.API_URL}/XPInfosNPS/inserir`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: AUTH_HEADER(),
                body: JSON.stringify(data),
            });

            if (response.ok) {
                return await response.json();
            }

            // Tratamento de erros para status >= 400
            const errorResponse = await response.json();
            throw new Error(JSON.stringify(errorResponse));
        } catch (error) {
            throw new Error(`Falha ao inserir NPS uploads: ${(error as Error).message}`);
        }
    }

    public async GetXPInfosNPSByAssessorId(assessorId: string): Promise<INPSUpload[]> {
        const url = `${this.API_URL}/XPInfosNPS/listar-por-assessor/${assessorId}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: AUTH_HEADER(),
            });

            if (response.ok) {
                return await response.json();
            }

            // Tratamento de erros para status >= 400
            const errorResponse = await response.json();
            throw new Error(JSON.stringify(errorResponse));
        } catch (error) {
            throw new Error(`Falha ao obter NPS uploads: ${(error as Error).message}`);
        }
    }

}
