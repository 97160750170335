import { INegocio } from "../Interface/interfaceNegocios";

export const toUpperSnakeCase = (str: string) => {
  return str.replace(/([A-Z])/g, '_$1').toUpperCase();
}

export function formatCurrencyBRL(valor: number | string) {
  const numero = Number(valor);
  if (isNaN(numero)) {
    return '-';
  }

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(numero);
}

export const formatStatusNegocios = (status: INegocio['Status']): string => {
  const statusMapping: Record<INegocio['Status'], string> = {
    LEAD: 'Lead',
    R1: 'R1',
    OUTRAS_REUNIOES: 'Outras Reuniões',
    AGUARDANDO_FECHAMENTO: 'Aguardando Fechamento',
    AGUARDANDO_ATIVACAO: 'Aguardando Ativação',
    CLIENTE: 'Cliente',
    CANCELADO: 'Cancelado',
    PERDEU: 'Perdeu'
  };

  return statusMapping[status] || 'Status Desconhecido';
};

export const truncateString = (string: string, maxLength: number) => {
  if (!string) return '';

  if (string.length > maxLength) {
    return `${string.slice(0, maxLength)}...`;
  }
  return string;
};

export const activityWriteDate = (date: any, activityAllDay: boolean) => {
  if (!date) return;

  let dateOfActivitie = new Date(date)

  if (!(dateOfActivitie instanceof Date)) return "...";

  let monthString = dateOfActivitie.toLocaleString('pt-BR', { month: "short" })
  // Pulling out . in the endc
  monthString = monthString.substring(0, monthString.length - 1);
  // Uppercase the first letter
  monthString = monthString.charAt(0).toUpperCase() + monthString.slice(1)

  let dayString = ("0" + dateOfActivitie.getDate()).slice(-2)
  let hourString = ("0" + dateOfActivitie.getHours()).slice(-2)
  let minutesString = ("0" + dateOfActivitie.getMinutes()).slice(-2)

  // 25/jun | 17:00h - Quarta-feira
  const weekday = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];

  let day = weekday[dateOfActivitie.getDay()];

  let newDateString = ""
  // Result
  if (activityAllDay) {
    newDateString = dayString + "/" + monthString + " - " + day
  } else {
    newDateString = dayString + "/" + monthString + "  |  " + hourString + ":" + minutesString + " - " + day
  }

  return newDateString
}

export function formatEstrategiaConsorcio(estrategia: string): string {
  const estrategiaMap: { [key: string]: string } = {
    'AQUISICAO_PLANEJADA': 'Aquisição Planejada',
    'OPERACAO_ESTRUTURADA': 'Operação Estruturada',
    'ALAVANCAGEM_FINANCEIRA': 'Alavancagem Financeira',
  };

  return estrategiaMap[estrategia] || estrategia;
}

export function parseBrazilianFloat(value: string | number): number {
  if (typeof value === 'number') {
    return value; // Retorna diretamente se já for um número
  }

  if (!value) return 0;


  // Remove todos os pontos (separador de milhar)
  // e substitui vírgula decimal por ponto
  const normalized = value.replace(/\./g, '').replace(',', '.');

  return parseFloat(normalized) || 0;
}
