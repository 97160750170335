import React, { useState } from 'react';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { enqueueSnackbar } from 'notistack';
import { formatCurrencyBRL, parseBrazilianFloat } from '../../../Helpers/formatters';
import XPInfosUploadsService from '../../../Services/XPInfosUploadsService';
import { IPlanilhaSaldoEmConta } from '../../../Interface/uploadsXPFiles';

interface IDataPreviewWithError extends IPlanilhaSaldoEmConta {
  error?: string;
}

export const UploadSaldoEmConta = () => {
  const [dataPreview, setDataPreview] = useState<IDataPreviewWithError[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [showList, setShowList] = useState(false);

  console.log(dataPreview)

  const uploadsService = new XPInfosUploadsService();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrors([]);
    setDataPreview([]);
    setProcessing(true);
    setLoadingUpload(true);
    setSubmitted(false);
    setShowList(false);

    const files = event.target.files;
    if (!files || !files[0]) {
      setProcessing(false);
      setLoadingUpload(false);
      return;
    }

    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];

        // Converte a planilha para JSON, cada linha será um array
        const jsonData: any[] = XLSX.utils.sheet_to_json(firstSheet, {
          header: 1,
          defval: '',
        });

        // Remove o cabeçalho (caso exista) e mapeia as colunas que nos interessam
        // Índices das colunas (começando em 0): A(0), D(3), E(4), F(5), H(7)
        const dadosImportados: IPlanilhaSaldoEmConta[] = jsonData
          .slice(1) // pula o cabeçalho, se existir
          .map((linha) => ({
            CodigoCliente: String(linha[0]),            // A
            SaldoAtual: parseBrazilianFloat(linha[3]) || 0,      // D
            D1: parseBrazilianFloat(linha[4]) || 0,              // E
            D2: parseBrazilianFloat(linha[5]) || 0,              // F
            SaldoProjetado: parseBrazilianFloat(linha[7]) || 0   // H
          }))
          // Ordena pelo SaldoAtual em ordem decrescente
          .sort((a, b) => b.SaldoAtual - a.SaldoAtual);

        setDataPreview(dadosImportados);
      } catch (error) {
        console.error('Falha ao carregar o arquivo:', error);
        setErrors([`Erro ao processar a planilha: ${(error as Error).message}`]);
      } finally {
        setProcessing(false);
        setLoadingUpload(false);
      }
    };

    reader.onerror = (e) => {
      setErrors([`Erro ao ler o arquivo: ${e.type}`]);
      setProcessing(false);
      setLoadingUpload(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleUpload = async () => {
    setLoadingUpload(true);
    setErrors([]);

    try {
      const response = await uploadsService.AddXPInfosSaldoEmConta(dataPreview);

      const { Sucesso, Erros } = response;

      const updatedDataPreview = dataPreview.map((item) => {
        const error = Erros.find((err: string) => {
          const match = err.match(/'(\d+)'/);
          return match && match[1] === String(item.CodigoCliente);
        });
        return {
          ...item,
          error: error ? error : undefined,
        };
      });

      updatedDataPreview.filter((row) => row.error);

      setDataPreview(updatedDataPreview);

      enqueueSnackbar('Dados enviados com sucesso!', { variant: 'success' });
    } catch (error: any) {
      console.error('Erro ao enviar dados:', error);
      setErrors([error.message]);
    } finally {
      setLoadingUpload(false);
      setSubmitted(true);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Upload de Saldo em Conta
      </Typography>

      {errors.length > 0 && (
        <Box display="flex" flexWrap="wrap" gap={1} mb={2}>
          {errors.map((error, index) => (
            <Chip key={index} label={error} color="error" />
          ))}
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 4 }}>
        <TextField
          type="file"
          onChange={handleFileChange}
          inputProps={{ accept: '.xls, .xlsx' }}
          variant="outlined"
        />
        {processing ? (
          <CircularProgress size={24} />
        ) : (
          <LoadingButton
            loading={loadingUpload}
            variant="contained"
            color="primary"
            onClick={handleUpload}
            disabled={!dataPreview.length}
          >
            Confirmar e Enviar
          </LoadingButton>
        )}
      </Box>

      {dataPreview.length > 0 && !submitted && (
        <Box display="flex" alignItems="center" gap={2} mb={2}
          sx={{
            width: 'max-content',
            p: 2,
            borderRadius: 2,
            backgroundColor: 'white',
          }}
        >
          <Typography>
            Total de itens importados: <strong>{dataPreview.length}</strong>
          </Typography>
          {showList ? (
            <Button
              variant="text"
              onClick={() => setShowList(false)}
            >
              Ocultar lista
            </Button>
          ) : (
            <Button
              variant="text"
              onClick={() => setShowList(true)}
            >
              Deseja visualizar a lista?
            </Button>
          )}
        </Box>
      )}

      {submitted && errors.length === 0 && (
        <Box display="flex" justifyContent="center" gap={2} mb={4}>
          <Box
            sx={{
              textAlign: 'center',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'white',
              color: 'success.main',
              minWidth: 150,
            }}
          >
            <CheckCircleOutline sx={{ fontSize: 40 }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 1 }}>
              {dataPreview.length - dataPreview.filter((row) => row.error).length}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'white',
              color: 'error.main',
              minWidth: 150,
            }}
          >
            <ErrorOutline sx={{ fontSize: 40 }} />
            <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 1 }}>
              {dataPreview.filter((row) => row.error).length}
            </Typography>
          </Box>
        </Box>
      )}

      {processing ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: '200px', mb: 3 }}
        >
          <CircularProgress />
        </Box>
      ) : (
        (showList || submitted) && errors.length === 0 && (
          <TableContainer component={Paper} sx={{ mt: 3 }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    Código Cliente (A)
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    Saldo Atual (D)
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    D1 (E)
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    D2 (F)
                  </TableCell>
                  <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    Saldo Projetado (H)
                  </TableCell>
                  {submitted && (
                    <TableCell align="center" sx={{ fontWeight: 'bold', fontSize: 12 }}>
                      Status
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {dataPreview.length > 0 ? (
                  dataPreview
                    .filter((row) => row.error)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ fontSize: 11 }}>{row.CodigoCliente}</TableCell>
                        <TableCell sx={{ fontSize: 11 }}>
                          {formatCurrencyBRL(row.SaldoAtual)}
                        </TableCell>
                        <TableCell sx={{ fontSize: 11 }}>
                          {formatCurrencyBRL(row.D1)}
                        </TableCell>
                        <TableCell sx={{ fontSize: 11 }}>
                          {formatCurrencyBRL(row.D2)}
                        </TableCell>
                        <TableCell sx={{ fontSize: 11 }}>
                          {formatCurrencyBRL(row.SaldoProjetado)}
                        </TableCell>
                        {submitted && (
                          <TableCell sx={{ fontSize: 11 }}>
                            {row.error ? (
                              <Box display="flex" alignItems="center" gap={1}>
                                <ErrorOutline color="error" fontSize="small" />
                                <Typography color="error" sx={{ fontSize: 11 }}>
                                  {row.error}
                                </Typography>
                              </Box>
                            ) : (
                              <Box display="flex" alignItems="center" gap={1}>
                                <CheckCircleOutline color="success" fontSize="small" />
                                <Typography color="success" sx={{ fontSize: 11 }}>
                                  Ok
                                </Typography>
                              </Box>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      Nenhuma informação carregada
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )
      )}
    </Box>
  );
};
