import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, Tooltip, ResponsiveContainer, CartesianGrid, YAxis } from 'recharts';
import { formatCurrencyBRL } from '../../../Helpers/formatters';
import { IXPInfosVencimentos } from '../../../Interface/uploadsXPFiles';

interface VencimentosBarChartProps {
  items: IXPInfosVencimentos[];
  onDateClick?: (fullDate: string) => void;
}

export const VencimentosBarChart: React.FC<VencimentosBarChartProps> = ({
  items,
  onDateClick,
}) => {
  const [chartData, setChartData] = useState<any[]>([]);

  useEffect(() => {
    const prepareChartData = () => {
      // 'Zera' a data de hoje no horário local
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      // Identifica o último vencimento nos itens
      const lastVencimentoDate = items.reduce((latest, item) => {
        const itemDate = new Date(item.DataVencimento);
        return itemDate > latest ? itemDate : latest;
      }, today);

      const diffInDays = Math.ceil(
        (lastVencimentoDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
      );

      const daysToShow = Math.max(30, diffInDays);

      // Mapa de datas (YYYY-MM-DD) -> volume
      const daysMap: { [key: string]: number } = {};

      // Preenche o mapa com zero de volume para todos os dias
      for (let i = 0; i <= daysToShow; i++) {
        // Cria uma cópia de 'today'
        const currentDate = new Date(today);
        currentDate.setDate(today.getDate() + i);

        // Monta a string no formato YYYY-MM-DD (local)
        const dayKey =
          currentDate.getFullYear().toString() +
          '-' +
          String(currentDate.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(currentDate.getDate()).padStart(2, '0');

        daysMap[dayKey] = 0;
      }

      // Agrega volumes nos dias corretos (se estiverem no mapa)
      items.forEach((item) => {
        const dateObj = new Date(item.DataVencimento);
        // Zera a hora local do vencimento
        dateObj.setHours(0, 0, 0, 0);

        // Cria a mesma chave local no formato YYYY-MM-DD
        const vencimentoKey =
          dateObj.getFullYear().toString() +
          '-' +
          String(dateObj.getMonth() + 1).padStart(2, '0') +
          '-' +
          String(dateObj.getDate()).padStart(2, '0');

        if (daysMap[vencimentoKey] !== undefined) {
          daysMap[vencimentoKey] += item.Volume;
        }
      });

      // Transforma o mapa num array para o gráfico
      const data = Object.entries(daysMap).map(([dateKey, volume]) => {
        // Recria o Date para extrair dia, mês, ano
        const dateParts = dateKey.split('-');
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // mês 0-based
        const dayNum = parseInt(dateParts[2], 10);

        // Cria um Date local
        const d = new Date(year, month, dayNum);

        // Formato DD/MM/YYYY (você pode personalizar como quiser)
        const fullDate =
          String(d.getDate()).padStart(2, '0') + '/' +
          String(d.getMonth() + 1).padStart(2, '0') + '/' +
          d.getFullYear();

        return {
          day: d.getDate().toString(), // exibe só o número do dia no eixo X
          fullDate,                   // data completa para exibir no tooltip
          volume,
        };
      });

      setChartData(data);
    };

    prepareChartData();
  }, [items]);

  const handleBarClick = (
    data: any,
    index: number
  ) => {
    if (onDateClick) {
      // Chamamos a prop com a data completa do item
      onDateClick(data.fullDate);
    }
  };

  return (
    <ResponsiveContainer width='100%' height={150}>
      <BarChart data={chartData}>
        <XAxis
          dataKey='day'
          // axisLine={false}
          // tickLine={false}
          axisLine={{ stroke: '#7c7c7ccc', strokeWidth: 0.5 }} // Define a cor e a largura da linha do eixo
          tickLine={{ stroke: '#7c7c7ccc', strokeWidth: 0.5 }} // Define a cor e a largura das linhas de tick

          tick={{ fontSize: 14, fill: '#202020' }}
        />
        <Tooltip
          labelFormatter={(label, payload) => {
            if (payload && payload.length > 0) {
              // A data completa está em payload[0].payload.fullDate
              return payload[0].payload.fullDate;
            }
            return label;
          }}
          formatter={(value: any) => formatCurrencyBRL(value as number)}
        />
        <Bar
          dataKey='volume'
          barSize={30}
          fill='#fdc106'
          onClick={handleBarClick}
          cursor='pointer'
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
