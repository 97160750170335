import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { UploadVencimentos } from '../../Common/XpInfos/UploadVencimentos';
import { UploadSaldoEmConta } from '../../Common/XpInfos/UploadSaldoEmConta';
import { useState } from 'react';
import { UploadNPS } from '../../Common/XpInfos/UploadNPS';

type TabsOptions = 'uploadVencimentos' | 'uploadSaldoEmConta' | 'uploadNPS';

export function UploadsXPFiles() {
  const [tabOption, setTabOption] = useState<TabsOptions>('uploadVencimentos');

  const handleChange = (event: React.SyntheticEvent, newValue: TabsOptions) => {
    setTabOption(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={tabOption}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label='Uploads Tabs' centered>
            <Tab label='Vencimentos' value='uploadVencimentos' />
            <Tab label='Saldo em Conta' value='uploadSaldoEmConta' />
            <Tab label='NPS' value='uploadNPS' />
          </TabList>
        </Box>

        <TabPanel value='uploadVencimentos'>
          <UploadVencimentos />
        </TabPanel>

        <TabPanel value='uploadSaldoEmConta'>
          <UploadSaldoEmConta />
        </TabPanel>

        <TabPanel value='uploadNPS'>
          <UploadNPS />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
