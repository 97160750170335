import React, { useState } from 'react';
import {
  Box,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { IPlanilhaDadosVencimentos } from '../../../Interface/uploadsXPFiles';
import XPInfosUploadsService from '../../../Services/XPInfosUploadsService';
import { enqueueSnackbar } from 'notistack';
import { formatCurrencyBRL } from '../../../Helpers/formatters';

interface IDataPreviewWithError extends IPlanilhaDadosVencimentos {
  error?: string;
}

export const UploadVencimentos = () => {
  const [dataPreview, setDataPreview] = useState<IDataPreviewWithError[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const uploadsService = new XPInfosUploadsService();

  const parseDate = (dateStr: string): Date | null => {
    const [day, month, year] = dateStr.split('/').map(Number);
    if (!day || !month || !year) return null;
    return new Date(year, month - 1, day);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setErrors([]);
    setDataPreview([]);
    setProcessing(true);
    setLoadingUpload(true);
    setSubmitted(false);

    const files = event.target.files;
    if (!files || !files[0]) {
      setProcessing(false);
      return;
    }

    const file = files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const firstSheet = workbook.Sheets[firstSheetName];
        const jsonData: any[] = XLSX.utils.sheet_to_json(firstSheet, {
          header: 1,
          defval: '',
        });

        const isLinhaValida = (linha: any[]): boolean => {
          const rawDate = linha[8];
          if (!rawDate) return false;

          const dataVencimento = parseDate(rawDate);
          if (!dataVencimento || isNaN(dataVencimento.getTime())) return false;

          // Define a data inicial como D-2 (2 dias atrás)
          const dataInicial = new Date();
          dataInicial.setDate(dataInicial.getDate() - 2);
          dataInicial.setHours(0, 0, 0, 0);

          const dataLimite = new Date();
          dataLimite.setDate(dataLimite.getDate() + 31);
          dataLimite.setHours(23, 59, 59, 999);

          return dataVencimento >= dataInicial && dataVencimento <= dataLimite;
        };


        const dadosFiltrados: IDataPreviewWithError[] = jsonData
          .slice(1)
          .filter((linha) => isLinhaValida(linha))
          .map((linha) => {
            const dataVencimento = parseDate(linha[8]);
            return {
              CodigoCliente: String(linha[1]),
              Volume: linha[10],
              SubProduto: linha[3],
              Ativo: linha[6],
              DataVencimento: dataVencimento!,
            };
          })
          .sort((a, b) => a.DataVencimento.getTime() - b.DataVencimento.getTime());

        setDataPreview(dadosFiltrados);
      } catch (error) {
        console.error('Falha ao carregar o arquivo:', error);
        setErrors([`Erro ao processar a planilha: ${(error as Error).message}`]);
      } finally {
        setProcessing(false);
        setLoadingUpload(false);
      }
    };

    reader.onerror = (e) => {
      setErrors([`Erro ao ler o arquivo: ${e.type}`]);
      setProcessing(false);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleUpload = async () => {
    setLoadingUpload(true);
    setErrors([]);

    try {
      const response = await uploadsService.AddXPInfosVencimentos(dataPreview);

      const { Sucesso, Erros } = response;

      const updatedDataPreview = dataPreview
        .map((item) => {
          const error = Erros.find((err: string) => {
            const codigoNoErro = err.match(/'(\d+)'/);
            return codigoNoErro && codigoNoErro[1] === String(item.CodigoCliente);
          });

          return { ...item, error: error ? error : undefined };
        })
        .sort((a, b) => (a.error && !b.error ? -1 : !a.error && b.error ? 1 : 0));

      setDataPreview(updatedDataPreview);

      enqueueSnackbar('Dados enviados com sucesso!', { variant: 'success' });
    } catch (error: any) {
      console.error('Erro ao enviar dados:', error);
      setErrors([error.message]);
    } finally {
      setLoadingUpload(false);
      setSubmitted(true);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h4' sx={{ mb: 2 }}>
        Upload de Vencimentos
      </Typography>

      {errors.length > 0 && (
        <Box display='flex' flexWrap='wrap' gap={1} mb={2}>
          {errors.map((error, index) => (
            <Chip key={index} label={error} color='error' />
          ))}
        </Box>
      )}

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 4 }}>
        <TextField
          type='file'
          onChange={handleFileChange}
          inputProps={{ accept: '.xls, .xlsx' }}
          variant='outlined'
        />
        {processing ? (
          <CircularProgress size={24} />
        ) : (
          <LoadingButton
            loading={loadingUpload}
            variant='contained'
            color='primary'
            onClick={handleUpload}
            disabled={!dataPreview.length}
          >
            Confirmar e Enviar
          </LoadingButton>
        )}
      </Box>

      {submitted && (
        <Box display='flex' justifyContent='center' gap={2} mb={4}>
          <Box
            sx={{
              textAlign: 'center',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'white',
              color: 'success.main',
              minWidth: 150,
            }}
          >
            <CheckCircleOutline sx={{ fontSize: 40 }} />
            <Typography variant='h4' sx={{ fontWeight: 'bold', mt: 1 }}>
              {dataPreview.length - dataPreview.filter((row) => row.error).length}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'white',
              color: 'error.main',
              minWidth: 150,
            }}
          >
            <ErrorOutline sx={{ fontSize: 40 }} />
            <Typography variant='h4' sx={{ fontWeight: 'bold', mt: 1 }}>
              {dataPreview.filter((row) => row.error).length}
            </Typography>
          </Box>
        </Box>
      )}

      {processing ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ height: '200px', backgroundColor: 'lightgrey', borderRadius: '8px', mb: 3 }}
        >
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
            Processando o arquivo, por favor aguarde...
          </Typography>
        </Box>
      ) : (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  Código Cliente (B)
                </TableCell>
                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  Volume (K)
                </TableCell>
                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  SubProduto (D)
                </TableCell>
                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  Ativo (G)
                </TableCell>
                <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  Data de Vencimento (I)
                </TableCell>
                {submitted && (
                  <TableCell align='center' sx={{ fontWeight: 'bold', fontSize: 12 }}>
                    Status
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPreview.length > 0 ? (
                dataPreview.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: 11 }}>{row.CodigoCliente}</TableCell>
                    <TableCell sx={{ fontSize: 11 }}>
                      {formatCurrencyBRL(row.Volume)}
                    </TableCell>
                    <TableCell sx={{ fontSize: 11 }}>{row.SubProduto}</TableCell>
                    <TableCell sx={{ fontSize: 11 }}>{row.Ativo}</TableCell>
                    <TableCell sx={{ fontSize: 11 }}>
                      {row.DataVencimento.toLocaleDateString('pt-BR')}
                    </TableCell>
                    {submitted && (
                      <TableCell sx={{ fontSize: 11 }}>
                        {row.error ? (
                          <Box display='flex' alignItems='center' gap={1}>
                            <ErrorOutline color='error' fontSize='small' />
                            <Typography color='error' sx={{ fontSize: 11 }}>
                              {row.error}
                            </Typography>
                          </Box>
                        ) : (
                          <Box display='flex' alignItems='center' gap={1}>
                            <CheckCircleOutline color='success' fontSize='small' />
                            <Typography color='success' sx={{ fontSize: 11 }}>
                              Ok
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align='center'>
                    Nenhuma informação carregada
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
