import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Paper
} from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';
import { formatCurrencyBRL } from '../../../Helpers/formatters';
import XPInfosUploadsService from '../../../Services/XPInfosUploadsService';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { useUserInfosContext } from '../../../Contexts/UserInfos';
import { IXPInfosSaldoEmConta } from '../../../Interface/uploadsXPFiles';
import PersonIcon from '@mui/icons-material/PersonOutlined';

export const XPInfosSaldoEmConta = () => {
  const [data, setData] = useState<IXPInfosSaldoEmConta[]>([]);
  const [filteredData, setFilteredData] = useState<IXPInfosSaldoEmConta[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [uploadTimestamp, setUploadTimestamp] = useState<string | null>(null);

  const storageHelper = new StorageHelper();
  const uploadsService = new XPInfosUploadsService();

  const { isAdmin, isSquadAdmin, userInfos, squadMembers } = useUserInfosContext();

  const defaultAssessorId = storageHelper.GetUsuarioId();
  const [selectedAdvisorId, setSelectedAdvisorId] = useState<string>(
    userInfos?.Id || defaultAssessorId
  );

  useEffect(() => {
    if (!selectedAdvisorId) return;

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await uploadsService.GetXPInfosSaldoEmContaByAssessorId(selectedAdvisorId);

        const sortedData = response.sort((a, b) => b.SaldoAtual - a.SaldoAtual);

        setData(sortedData);
        setFilteredData(sortedData);

        if (sortedData.length > 0) {
          const timestamp = new Date(sortedData[0].UploadTimestamp);

          timestamp.setHours(timestamp.getHours() - 3);

          const formattedTimestamp = timestamp.toLocaleString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
          });
          setUploadTimestamp(formattedTimestamp);
        }
      } catch (error) {
        console.error('Erro ao buscar dados de Saldo em Conta:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedAdvisorId]);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setFilteredData(data);
      return;
    }

    const query = searchQuery.toLowerCase();
    const filtered = data.filter((item) =>
      JSON.stringify(item).toLowerCase().includes(query)
    );
    setFilteredData(filtered);
  }, [data, searchQuery]);

  const totalSaldoAtual = useMemo(() => {
    return filteredData.reduce((acc, curr) => acc + (curr.SaldoAtual || 0), 0);
  }, [filteredData]);

  const columns: GridColDef[] = [
    {
      field: 'NomeCliente',
      headerName: 'Cliente',
      flex: 1.5,
      renderHeader: () => <strong>Cliente</strong>,
      renderCell: (params) => {
        const clienteId = params.row.ClienteId;
        return (
          <a
            href={`/app/perfil-cliente/${clienteId}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#43425D', textDecoration: 'none', cursor: 'pointer', fontWeight: 'bold' }}
          >
            {params.value}
          </a>
        );
      },
    },
    {
      field: 'CodigoCliente',
      headerName: 'Conta',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Conta</strong>,
    },
    {
      field: 'NomeAssessor',
      headerName: 'Assessor',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Assessor</strong>,
    },
    {
      field: 'SaldoAtual',
      headerName: 'Saldo Atual',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Saldo Atual</strong>,
      renderCell: (params) => {
        const value = params.value;
        const color = value < 0 ? 'error.main' : 'default';
        return (
          <Typography
            sx={{
              fontWeight: 'bold',
              color: color,
            }}
          >
            {formatCurrencyBRL(value)}
          </Typography>
        );
      },
    },
    {
      field: 'D1',
      headerName: 'D+1',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>D+1</strong>,
      renderCell: (params) => formatCurrencyBRL(params.value),
    },
    {
      field: 'D2',
      headerName: 'D+2',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>D+2</strong>,
      renderCell: (params) => formatCurrencyBRL(params.value),
    },
    {
      field: 'SaldoProjetado',
      headerName: 'Saldo Projetado',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Saldo Projetado</strong>,
      renderCell: (params) => formatCurrencyBRL(params.value),
    },
  ];

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='60vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h3'>Saldo em Conta</Typography>

        <Stack direction='row' spacing={4} alignItems='center'
          sx={{
            px: 3,
            backgroundColor: '#fff',
            borderRadius: 1,
            height: '60px',
            position: 'relative',
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <PersonIcon sx={{ color: '#bcbbce' }} />
            <Typography variant='h5'>{filteredData.length}</Typography>
          </Stack>

          <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Typography variant='h6' sx={{ fontWeight: 'normal' }}>
              Saldo atual
            </Typography>
            <Typography variant='h5' color={totalSaldoAtual < 0 ? 'error' : '#5acea4'}>
              {formatCurrencyBRL(totalSaldoAtual)}
            </Typography>
          </Box>

          {isSquadAdmin && !isAdmin && (
            <Box sx={{ my: 2 }}>
              <FormControl size='small' sx={{ minWidth: 200 }}>
                <InputLabel id='select-advisor-label'>Selecione um assessor</InputLabel>
                <Select
                  labelId='select-advisor-label'
                  id='select-advisor'
                  value={selectedAdvisorId}
                  label='Selecione um assessor'
                  onChange={(e) => setSelectedAdvisorId(e.target.value as string)}
                >
                  {squadMembers.map((advisor: any) => (
                    <MenuItem
                      key={advisor.Usuario.Id}
                      value={advisor.Usuario.Id}
                      sx={{ fontWeight: userInfos.Id === advisor.Usuario.Id ? 'bold' : 'normal' }}
                    >
                      {advisor.Usuario.NomeCompleto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Stack>
      </Box>

      <Paper sx={{ p: 3, mb: 2, position: 'relative' }}>
        {uploadTimestamp && (
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              opacity: 0.6,
              fontSize: '0.8rem',
            }}
          >
            Última atualização: {uploadTimestamp}
          </Typography>
        )}


        <Box sx={{ mb: 2 }}>
          <TextField
            label='Pesquisar'
            size='small'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <DataGrid
          sx={{
            fontSize: 15,
            border: 'none',
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #efefef',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            minHeight: 500,
          }}
          rows={filteredData}
          columns={columns}
          checkboxSelection={false}
          autoHeight
          autoPageSize
          pageSize={10}
          rowsPerPageOptions={[10, 25, 50]}
          getRowId={(row) => row.Id}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Paper>
    </Box>
  );
};
