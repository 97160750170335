import * as XLSX from 'xlsx';

export function parseExcelDate(val: any): Date {
  // 1) Se for número, usamos XLSX.SSF.parse_date_code
  if (typeof val === 'number') {
    const result = XLSX.SSF.parse_date_code(val);
    if (!result) {
      return new Date('1970-01-01');
    }
    // result => { y, m, d, H, M, S, ... }
    return new Date(result.y, result.m - 1, result.d, result.H, result.M, result.S);
  }

  // 2) Se for string, tentamos 'new Date(...)'
  if (typeof val === 'string') {
    const parsed = new Date(val);
    if (!isNaN(parsed.getTime())) {
      return parsed;
    }
    // Se falhar, podemos tentar parse manual dd/MM/yyyy ...
    // ou retornar data default
  }

  // 3) Data default se não for número nem string parseável
  return new Date('1970-01-01');
}
