import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { parseExcelDate } from '../../../Helpers/parseExcelDate';
import XPInfosUploadsService from '../../../Services/XPInfosUploadsService';
import { INPSUploadDTO } from '../../../Interface/uploadsXPFiles';

interface IMergedNPSWithError {
  SurveyId: string;
  CodigoCliente: string;
  DataEntrega: Date;
  DataResposta?: Date;
  SurveyStatus: string;
  Nota?: number | null;
  Comentario?: string;
  error?: string;
}

interface IExpressRow {
  SurveyId: string;
  CodigoCliente: string;
  DataEntrega: Date;
  DataResposta?: Date;
  SurveyStatus: string;
}

interface IResponsesRow {
  SurveyId: string;
  Tipo: string;
  Nota: number | null;
  Comentario: string;
}

export const UploadNPS = () => {
  const [expressData, setExpressData] = useState<IExpressRow[]>([]);
  const [responsesData, setResponsesData] = useState<IResponsesRow[]>([]);

  const [mergedData, setMergedData] = useState<IMergedNPSWithError[]>([]);

  const [uploading, setUploading] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);

  const uploadsService = new XPInfosUploadsService();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    setErrors([]);
    setExpressData([]);
    setResponsesData([]);
    setMergedData([]);

    const fileList = Array.from(e.target.files);

    fileList.forEach(async (file) => {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const jsonData: any[][] = XLSX.utils.sheet_to_json(sheet, { header: 1, defval: '' });

        if (file.name.includes('ExpressReport')) {
          processExpressReport(jsonData);
        } else if (file.name.includes('Assessores_Responses')) {
          processAssessoresResponses(jsonData);
        } else {
          setErrors((prev) => [...prev, `Arquivo '${file.name}' não reconhecido.`]);
        }
      } catch (err) {
        setErrors((prev) => [
          ...prev,
          `Erro ao processar '${file.name}': ${(err as Error).message}`
        ]);
      }
    });
  };

  const processExpressReport = (rows: any[][]) => {
    try {
      const body = rows.slice(3); // pula 3 linhas
      const hojeMais30 = new Date();
      hojeMais30.setDate(hojeMais30.getDate() + 30);

      const parsed = body.map((linha) => {
        const rawSurveyId = linha[0];
        const rawCodigoCliente = linha[2];
        const rawDataEntrega = linha[4];
        const rawDataResposta = linha[5];
        const rawSurveyStatus = linha[7];

        const dataEntrega = parseExcelDate(rawDataEntrega);
        const dataResposta = rawDataResposta ? parseExcelDate(rawDataResposta) : undefined;

        return {
          SurveyId: String(rawSurveyId || ''),
          CodigoCliente: String(rawCodigoCliente || ''),
          DataEntrega: dataEntrega,
          DataResposta: dataResposta,
          SurveyStatus: String(rawSurveyStatus || '')
        };
      });

      const filtered = parsed.filter((item) => {
        const condData = item.DataEntrega.getTime() <= hojeMais30.getTime();
        const condStatus =
          item.SurveyStatus !== 'DELIVERY_BOUNCED' &&
          item.SurveyStatus !== 'NOT_SAMPLED';
        return condData && condStatus;
      });

      setExpressData(filtered);
    } catch (err) {
      setErrors((prev) => [...prev, `Erro ao ler ExpressReport: ${(err as Error).message}`]);
    }
  };

  // Processamento do Assessores_Responses
  const processAssessoresResponses = (rows: any[][]) => {
    try {
      const body = rows.slice(3);

      const parsed = body.map((linha) => {
        const rawSurveyId = linha[0];
        const rawTipo = String(linha[11] || '');
        let nota: number | null = null;
        let comentario = '';

        if (rawTipo === 'XP aniversario') {
          const rawNota = linha[12];
          const rawComment = String(linha[24] || '');
          nota = rawNota ? parseFloat(rawNota) : null;
          comentario = rawComment;
        } else if (rawTipo === 'XP onboarding') {
          const rawNota = linha[13];
          const rawComment = String(linha[21] || '');
          nota = rawNota ? parseFloat(rawNota) : null;
          comentario = rawComment;
        }

        return {
          SurveyId: String(rawSurveyId || ''),
          Tipo: rawTipo,
          Nota: nota,
          Comentario: comentario
        };
      });

      const filtered = parsed.filter((item) => item.Nota !== null);
      setResponsesData(filtered);
    } catch (err) {
      setErrors((prev) => [
        ...prev,
        `Erro ao ler Assessores_Responses: ${(err as Error).message}`
      ]);
    }
  };

  // UseEffect para mesclar e enviar
  useEffect(() => {
    // Se expressData e responsesData estão ok e ainda não fizemos merged
    if (expressData.length > 0 && responsesData.length > 0 && mergedData.length === 0) {
      mergeAndUpload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expressData, responsesData]);

  // Mescla e envia ao back-end
  const mergeAndUpload = async () => {
    try {
      setUploading(true);
      setErrors([]);

      // Mesclar
      const merged = expressData.map((exp) => {
        let nota: number | null = null;
        let comentario = '';

        if (exp.DataResposta) {
          const match = responsesData.find((r) => r.SurveyId === exp.SurveyId);
          if (match) {
            nota = match.Nota;
            comentario = match.Comentario;
          }
        }

        // Adicionamos `error` vazio inicialmente
        return {
          SurveyId: exp.SurveyId,
          CodigoCliente: exp.CodigoCliente,
          DataEntrega: exp.DataEntrega,
          DataResposta: exp.DataResposta,
          SurveyStatus: exp.SurveyStatus,
          Nota: nota,
          Comentario: comentario,
          error: undefined
        };
      });

      // Montar DTO
      const npsDto: INPSUploadDTO[] = merged.map(item => ({
        CodigoCliente: item.CodigoCliente,
        SurveyId: item.SurveyId,
        DataEntrega: item.DataEntrega.toISOString(),
        DataResposta: item.DataResposta ? item.DataResposta.toISOString() : null,
        SurveyStatus: item.SurveyStatus,
        Nota: item.Nota ?? null,
        Comentario: item.Comentario ?? ''
      }));

      const response = await uploadsService.AddXPInfosNPSUploads(npsDto);

      const { Sucesso, Erros } = response;

      const updatedMerged = merged.map((item) => {
        const foundError = Erros.find((errString: string) => {
          const match = errString.match(/'(\d+)'/);
          return match && match[1] === item.CodigoCliente;
        });
        return {
          ...item,
          error: foundError ? foundError : undefined
        };
      });

      setMergedData(updatedMerged);
    } catch (error: any) {
      setErrors([error.message]);
    } finally {
      setUploading(false);
    }
  };

  // Cálculo de erros e sucessos
  const totalErros = mergedData.filter(m => m.error).length;
  const totalSucessos = mergedData.length - totalErros;

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant='h4' sx={{ mb: 2 }}>
        Upload Automático de NPS
      </Typography>

      {errors.length > 0 && (
        <Box display='flex' flexWrap='wrap' gap={1} mb={2}>
          {errors.map((error, idx) => (
            <Chip key={idx} label={error} color='error' />
          ))}
        </Box>
      )}

      <Box sx={{ mb: 3 }}>
        <Button variant='contained' component='label'>
          Selecione os arquivos
          <input
            hidden
            multiple
            type='file'
            accept='.xls,.xlsx'
            onChange={handleFileChange}
          />
        </Button>
      </Box>

      <Box sx={{ mb: 2 }}>
        <Typography>Arquivos processados:</Typography>
        <ul>
          <li>ExpressReport: {expressData.length} registros</li>
          <li>Assessores_Responses: {responsesData.length} registros</li>
        </ul>
      </Box>

      {uploading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{ height: '60px', mt: 2 }}
        >
          <CircularProgress />
          <Typography sx={{ ml: 2 }}>Enviando ao servidor...</Typography>
        </Box>
      )}

      {mergedData.length > 0 && (
        <Box display='flex' justifyContent='center' gap={4} mt={3} mb={3}>
          <Box
            sx={{
              textAlign: 'center',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'white',
              color: 'success.main',
              minWidth: 150,
            }}
          >
            <CheckCircleOutline sx={{ fontSize: 40 }} />
            <Typography variant='h4' sx={{ fontWeight: 'bold', mt: 1 }}>
              {totalSucessos}
            </Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              p: 2,
              borderRadius: 2,
              backgroundColor: 'white',
              color: 'error.main',
              minWidth: 150,
            }}
          >
            <ErrorOutline sx={{ fontSize: 40 }} />
            <Typography variant='h4' sx={{ fontWeight: 'bold', mt: 1 }}>
              {totalErros}
            </Typography>
          </Box>
        </Box>
      )}

      {mergedData.length > 0 && (
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableHead>
              <TableRow>
                <TableCell>SurveyId</TableCell>
                <TableCell>CodigoCliente</TableCell>
                <TableCell>DataEntrega</TableCell>
                <TableCell>DataResposta</TableCell>
                <TableCell>SurveyStatus</TableCell>
                <TableCell>Nota</TableCell>
                <TableCell>Comentário</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mergedData.map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>{row.SurveyId}</TableCell>
                  <TableCell>{row.CodigoCliente}</TableCell>
                  <TableCell>
                    {row.DataEntrega.toLocaleDateString('pt-BR')}
                  </TableCell>
                  <TableCell>
                    {row.DataResposta
                      ? row.DataResposta.toLocaleDateString('pt-BR')
                      : ''}
                  </TableCell>
                  <TableCell>{row.SurveyStatus}</TableCell>
                  <TableCell>{row.Nota ?? ''}</TableCell>
                  <TableCell>{row.Comentario ?? ''}</TableCell>
                  <TableCell>
                    {row.error ? (
                      <Box display='flex' alignItems='center' gap={1}>
                        <ErrorOutline color='error' fontSize='small' />
                        <Typography color='error'>
                          {row.error}
                        </Typography>
                      </Box>
                    ) : (
                      <Box display='flex' alignItems='center' gap={1}>
                        <CheckCircleOutline color='success' fontSize='small' />
                        <Typography color='success'>Ok</Typography>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};
