import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './Config/msalConfig';
import { SnackbarProvider } from 'notistack'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserInfosProvider } from './Contexts/UserInfos';

const theme = createTheme({
  palette: {
    // type: 'light',
    primary: {
      main: '#fdc106',
    },
    secondary: {
      main: '#202020',
      contrastText: '#d6d6d6',
    },
    text: {
      primary: '#636363',
    },
    error: {
      main: '#f46a6a',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ffc542',
    },
    success: {
      main: '#34c38f',
      contrastText: 'rgba(255,255,255,0.87)',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro',
    body1: {
      fontSize: 14,
      color: "#43425D"
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 600,
    },
    h1: {
      fontSize: 27,
      fontWeight: 600,
      color: "#43425D"
    },
    h2: {
      fontSize: 24,
      fontWeight: 600,
      color: "#43425D"
    },
    h3: {
      fontSize: 19,
      fontWeight: 600,
      color: "#43425D"
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
      color: "#43425D"
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      color: "#43425D"
    },
    h6: {
      fontSize: 12,
      fontWeight: 600,
      color: "#43425D"
    },
    subtitle1: {
      fontSize: 17,
      fontWeight: 500,
      color: "#43425D"
    },
    subtitle2: {
      fontSize: 15,
      fontWeight: 500,
      color: "#43425D"
    },
    body2: {
      fontSize: 10,
      color: "#43425D"
    },
  },
  shape: {
    borderRadius: 5,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
  },

})

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <MsalProvider instance={msalInstance}>
      <SnackbarProvider>
        <UserInfosProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </UserInfosProvider>
      </SnackbarProvider>
    </MsalProvider>
  </ThemeProvider>
  ,
  document.getElementById('root')
);