import React, { useEffect, useState, useMemo } from 'react';
import {
  Box,
  TextField,
  Typography,
  CircularProgress,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack
} from '@mui/material';
import { DataGrid, GridColDef, ptBR } from '@mui/x-data-grid';
import XPInfosUploadsService from '../../../Services/XPInfosUploadsService';
import StorageHelper from '../../../Services/Helpers/StorageHelper';
import { formatCurrencyBRL } from '../../../Helpers/formatters';
import SearchIcon from '@mui/icons-material/Search';
import { VencimentosBarChart } from '../../Common/XpInfos/VencimentosBarChart';
import { useUserInfosContext } from '../../../Contexts/UserInfos';
import { IXPInfosVencimentos } from '../../../Interface/uploadsXPFiles';
import PersonIcon from '@mui/icons-material/PersonOutlined';

export const XPInfosVencimentos = () => {
  const [data, setData] = useState<IXPInfosVencimentos[]>([]);
  const [filteredData, setFilteredData] = useState<IXPInfosVencimentos[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const storageHelper = new StorageHelper();
  const uploadsService = new XPInfosUploadsService();

  const { isAdmin, isSquadAdmin, userInfos, squadMembers } = useUserInfosContext();

  const defaultAssessorId = storageHelper.GetUsuarioId();

  const [selectedAdvisorId, setSelectedAdvisorId] = useState<string>(
    userInfos?.Id || defaultAssessorId
  );

  useEffect(() => {
    if (!selectedAdvisorId) return;

    const fetchData = async () => {
      setLoading(true);
      setData([]);
      setSelectedDate(null);

      try {
        const response = await uploadsService.GetXPInfosByAssessorId(selectedAdvisorId);

        const sortedData = response.sort((a, b) =>
          new Date(a.DataVencimento).getTime() - new Date(b.DataVencimento).getTime()
        );

        setData(sortedData);
        setFilteredData(sortedData);
      } catch (error) {
        console.error('Erro ao buscar os dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedAdvisorId]);

  useEffect(() => {
    let temp = [...data];

    if (searchQuery.trim() !== '') {
      const query = searchQuery.toLowerCase();
      temp = temp.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(query)
      );
    }

    if (selectedDate) {
      temp = temp.filter((item) => {
        const d = new Date(item.DataVencimento);
        const day = String(d.getDate()).padStart(2, '0');
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const year = d.getFullYear();
        const fullDate = `${day}/${month}/${year}`;

        return fullDate === selectedDate;
      });
    }

    setFilteredData(temp);
  }, [data, searchQuery, selectedDate]);

  const totalVolume = useMemo(() => {
    return filteredData.reduce((acc, curr) => acc + (curr.Volume || 0), 0);
  }, [filteredData]);

  const totalAtivos = useMemo(() => {
    return filteredData.length;
  }, [filteredData]);

  const handleDateClick = (clickedDate: string) => {
    setSelectedDate(clickedDate);
  };

  const clearDateFilter = () => {
    setSelectedDate(null);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const columns: GridColDef[] = [
    {
      field: 'NomeCliente',
      headerName: 'Cliente',
      flex: 1.5,
      headerAlign: 'center',
      renderCell: (params) => {
        const clienteId = params.row.ClienteId;
        return (
          <a
            href={`/app/perfil-cliente/${clienteId}`}
            target='_blank'
            rel='noopener noreferrer'
            style={{ color: '#43425D', textDecoration: 'none', cursor: 'pointer', fontWeight: 'bold' }}
          >
            {params.value}
          </a>
        );
      },
      renderHeader: () => <strong>Cliente</strong>,
    },
    {
      field: 'CodigoCliente',
      headerName: 'Conta',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Conta</strong>,
    },
    {
      field: 'NomeAssessor',
      headerName: 'Assessor',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Assessor</strong>,
    },
    {
      field: 'SubProduto',
      headerName: 'Sub Produto',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Sub Produto</strong>,
    },
    {
      field: 'Ativo',
      headerName: 'Ativo',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>Ativo</strong>,
    },
    {
      field: 'Volume',
      headerName: 'Volume',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <span>
          {formatCurrencyBRL(params.value)}
        </span>
      ),
      renderHeader: () => <strong>Volume</strong>,
    },
    {
      field: 'DataVencimento',
      headerName: 'Vencimento',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) => new Date(params.row.DataVencimento),
      renderCell: (params) => {
        const today = new Date();
        const vencimento = params.value as Date;
        const diffInDays = Math.ceil(
          (vencimento.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)
        );

        let color = 'default';
        if (diffInDays <= 0) color = 'red';
        else if (diffInDays <= 20) color = 'default';

        return (
          <span style={{ color }}>
            {vencimento.toLocaleDateString('pt-BR')}
          </span>
        );
      },
      renderHeader: () => <strong>Vencimento</strong>,
    },
  ];

  if (loading) {
    return (
      <Box display='flex' justifyContent='center' alignItems='center' height='60vh'>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      <Box
        sx={{
          mb: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h3'>Vencimentos</Typography>

        <Stack direction='row' spacing={4} alignItems='center'
          sx={{
            px: 3,
            backgroundColor: '#fff',
            borderRadius: 1,
            height: '60px',
            position: 'relative',
          }}
        >
          <Stack direction='row' spacing={1} alignItems='center'>
            <PersonIcon sx={{ color: '#bcbbce' }} />
            <Typography variant='h5'>{totalAtivos}</Typography>
          </Stack>

          <Typography variant='h5' color='#5acea4'>
            {formatCurrencyBRL(totalVolume)}
          </Typography>

          {isSquadAdmin && !isAdmin && (
            <Box sx={{ my: 2 }}>
              <FormControl size='small' sx={{ minWidth: 200 }}>
                <InputLabel id='select-advisor-label'>Selecione um assessor</InputLabel>
                <Select
                  labelId='select-advisor-label'
                  id='select-advisor'
                  value={selectedAdvisorId}
                  label='Selecione um assessor'
                  onChange={(e) => setSelectedAdvisorId(e.target.value as string)}
                >
                  {squadMembers.map((advisor: any) => (
                    <MenuItem
                      key={advisor.Usuario.Id}
                      value={advisor.Usuario.Id}
                      sx={{
                        fontWeight: userInfos.Id === advisor.Usuario.Id ? 'bold' : 'normal',
                      }}
                    >
                      {advisor.Usuario.NomeCompleto}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        </Stack>
      </Box>


      {data && data.length > 0 && (
        <Box sx={{
          mb: 2,
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: 1,
          px: 1, pt: 2,
        }}>
          <Typography variant='h3' sx={{ ml: 2, fontWeight: 'normal', mb: 2, textAlign: 'center', color: '#7c7c7c' }}>Acumulado por dia</Typography>
          <VencimentosBarChart items={data} onDateClick={handleDateClick} />
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          backgroundColor: '#ffffff',
          borderRadius: 1,
          p: 3,
        }}
      >
        {selectedDate && (
          <Box
            sx={{ mb: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            <Typography variant='subtitle1'>
              Filtrando pela data: <strong>{selectedDate}</strong>
            </Typography>
            <Button variant='outlined' onClick={clearDateFilter} sx={{ ml: 2 }}>
              Limpar Filtro
            </Button>
          </Box>
        )}

        <Box sx={{ mb: 2 }}>
          <TextField
            label='Pesquisar'
            sx={{ width: 250 }}
            autoComplete='off'
            size='small'
            value={searchQuery}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <DataGrid
          sx={{
            fontSize: 15,
            border: 'none',
            '& .MuiDataGrid-cell': {
              borderBottom: '1px solid #d1aeed1f',
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
          }}
          rows={filteredData}
          columns={columns}
          checkboxSelection={false}
          autoHeight
          autoPageSize
          pageSize={10}
          rowsPerPageOptions={[50]}
          getRowId={(row) => row.Id}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
        />
      </Box>
    </Box>
  );
};
