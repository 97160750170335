import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect
} from 'react';
import DashBoardSevice from '../Services/DashboardService';
import StorageHelper from '../Services/Helpers/StorageHelper';
import UserService from '../Services/UserService';

interface IUserInfosContext {
  userInfos: any;
  squadMembers: any[];
  isAdmin: boolean;
  isSquadAdmin: boolean;
}

const UserInfosContext = createContext<IUserInfosContext>({} as IUserInfosContext);

export const UserInfosProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dashService = new DashBoardSevice();
  const userService = new UserService();
  const storageHelper = new StorageHelper();

  const assessorId = useMemo(() => {
    return storageHelper.GetUsuarioId();
  }, [storageHelper]);

  const [userInfos, setUserInfos] = useState<any>({});
  const [allSquadsMembers, setAllSquadsMembers] = useState<any[]>([]);

  useEffect(() => {
    if (assessorId) {
      userService
        .GetUserInfos(assessorId)
        .then((res) => setUserInfos(res))
        .catch(() => { /* tratar erro */ });

      dashService
        .ListarSquadsParticipantes()
        .then((res) => setAllSquadsMembers(res))
        .catch(() => { /* tratar erro */ });
    }
  }, [assessorId]);

  const squadMembers = useMemo(() => {
    if (!allSquadsMembers?.length) return [];

    let userLogged: any = {};
    const userSquadId = userInfos?.Squad?.Id;
    if (!userSquadId) return [];

    let newValues = allSquadsMembers
      .map((member: any) => {
        if (member?.SquadId === userSquadId) {
          if (member?.UsuarioId === assessorId) {
            userLogged = member;
          } else {
            return member;
          }
        }
      })
      .filter((m: any) => m !== undefined)
      .sort((a: any, b: any) => {
        const nameA = a?.Usuario ? `${a.Usuario.Nome} ${a.Usuario.Sobrenome}` : '';
        const nameB = b?.Usuario ? `${b.Usuario.Nome} ${b.Usuario.Sobrenome}` : '';
        return nameA.localeCompare(nameB);
      });
    
    newValues = [userLogged, ...newValues].filter((m) => Object.keys(m).length !== 0);

    return newValues;
  }, [userInfos, allSquadsMembers, assessorId]);

  const isAdmin = useMemo(() => {
    const role = storageHelper.GetUsuarioRole();
    return role === 'Admin' || role === 'Administrador';
  }, [storageHelper]);

  const isSquadAdmin = useMemo(() => {
    return !!userInfos?.SquadAdmin;
  }, [userInfos]);

  return (
    <UserInfosContext.Provider
      value={{
        userInfos,
        squadMembers,
        isAdmin,
        isSquadAdmin,
      }}
    >
      {children}
    </UserInfosContext.Provider>
  );
};

export const useUserInfosContext = (): IUserInfosContext => {
  const context = useContext(UserInfosContext);
  if (!context) {
    throw new Error('useUserInfosContext must be used within a UserInfosProvider');
  }
  return context;
};
