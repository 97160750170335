import { DataGrid, GridColDef, GridValueFormatterParams, ptBR } from '@mui/x-data-grid'
import React, { useMemo, useState } from 'react';
import { interfaceClient } from '../../../../Interface/interfaceClient';
import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import ClientesService from '../../../../Services/ClientesService';
import { interfaceSchedule } from '../../../../Interface/intarfaceSchedule';
import { createTask } from '../../NewProfileClient/crudTask';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import { enqueueSnackbar } from 'notistack';
import StorageHelper from '../../../../Services/Helpers/StorageHelper';

export interface DataGridComponentProps {
  clientes: any[];
  loading: boolean;
  columns: ('NomeCompleto' | 'Origem' | 'Temperatura' | 'Preco' | 'UltimaAtividade' |
    'PotencialDeInvestimento' | 'SubStatus' | 'TelefoneContatoPrimario' | 'CodigoCliente' |
    'MotivoDePerda' | 'Acoes')[];
}

const DataGridComponent: React.FC<DataGridComponentProps> = ({ clientes, columns, loading }) => {
  const clientsService = new ClientesService();
  const storageHelper = new StorageHelper();
  const assessorId = storageHelper.GetUsuarioId();

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedClient, setSelectedClient] = useState<interfaceClient | null>(null);
  const [processing, setProcessing] = useState(false);
  const [tempDescription, setTempDescription] = useState<string>('');

  const getPreviousMonthName = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toLocaleString('pt-BR', { month: 'long' });
  };


  const handleOpenDialog = (client: interfaceClient) => {
    setSelectedClient(client);
    setTempDescription(`Fechamento de ${getPreviousMonthName()} realizado com sucesso!`);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedClient(null);
  };

  const formatterColorTemperatura = (value: string) => {
    switch (value) {
      case 'Quente': {
        return 'green';
      }
      case 'Morno': {
        return '#7D9F7D ';
      }
      case 'Frio': {
        return '#856F97';
      }
      default: {
        return '';
      }
    }
  }

  const formatterAtividade = (value: any) => {
    if (value.value == null) {
      return '-'
    }
    let createDate = new Date(value.value)
    createDate.setHours(createDate.getHours() - 3)
    let dateNow = new Date()
    let difference = dateNow.getTime() - createDate.getTime()
    let days = Math.floor(difference / (1000 * 3600 * 24));

    if (dateNow.getDate() - createDate.getDate() == 1) {
      if ((dateNow.getMonth() == createDate.getMonth()) && (dateNow.getFullYear() == createDate.getFullYear())) {
        days = 1
      }
    }
    return days
  }

  const formatterPreco = (value: any) => {
    let v = value.value
    if (!(v > 0)) v = 0
    return (
      v.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    )
  }

  async function handleMonthlyClosure(client: interfaceClient) {
    try {
      const updatedClient: interfaceClient = {
        ...client,
        UltimoFechamentoMensal: new Date().toISOString(),
        UltimaAtividade: new Date().toISOString(),
      };

      await clientsService.UpdateClientes(updatedClient);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao atualizar o fechamento mensal', { variant: 'error' });
    }
  }

  function isMonthlyClosureEnabled(lastMonthlyClosure?: Date | string | null): boolean {
    if (!lastMonthlyClosure) return true;

    const closureDate = new Date(lastMonthlyClosure);
    const today = new Date();

    const closureMonth = closureDate.getMonth();
    const closureYear = closureDate.getFullYear();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Returns true if lastMonthlyClosure is from a different month/year than the current one
    return !(closureMonth === currentMonth && closureYear === currentYear);
  }

  async function createMonthlyClosureTask(client: interfaceClient) {
    const task: interfaceSchedule = {
      AssessorUsuarioId: assessorId,
      ClienteId: client.Id,
      Title: 'Fechamento mensal',
      Descricao: tempDescription,
      Visto: false,
      DataEvento: new Date(),
      DataTermino: new Date(),
      AtividadeFinalizadaData: new Date(),
      StatusTarefa: 'FINISHED',
      AtividadeFinalizada: true,
      AtividadeTodoDia: false,
    };

    try {
      await createTask(task);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Erro ao criar a tarefa de fechamento mensal.', { variant: 'error' });
    }
  }

  const handleConfirmClosure = async () => {
    if (!selectedClient) return;
    setProcessing(true);
    try {
      await createMonthlyClosureTask(selectedClient);

      await handleMonthlyClosure(selectedClient);

      const index = clientes.findIndex((c) => c.Id === selectedClient.Id);
      if (index >= 0) {
        clientes[index].UltimoFechamentoMensal = new Date().toISOString();
        clientes[index].UltimaAtividade = new Date().toISOString();
      }

      setTempDescription('');
    } catch (error) {
      console.error(error);
    } finally {
      setProcessing(false);
      handleCloseDialog();
    }
  };

  const buildColumns = useMemo(() => {
    const result: GridColDef[] = []

    columns.forEach(v => {
      if (v === 'NomeCompleto') {
        result.push({
          field: 'NomeCompleto',
          headerName: 'Nome',
          headerAlign: 'center',
          cellClassName: 'cell-class',
          flex: 1,
          renderHeader: () => {
            return <strong>Nome</strong>
          },
          renderCell: (params: any) => {
            return <strong>{params.value}</strong>
          }
        })
      } else if (v === 'Origem') {
        result.push({
          field: 'Origem',
          headerName: 'Origem',
          type: 'text',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          cellClassName: 'cell-class',
          renderHeader: () => {
            return <strong>Origem</strong>
          },
          renderCell: (params: any) => {
            return <span>{params.value}</span>
          }
        })
      } else if (v === 'Temperatura') {
        result.push({
          field: 'Temperatura',
          headerName: 'Temperatura',
          type: 'text',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          cellClassName: 'cell-class',
          renderHeader: () => {
            return <strong>Temperatura</strong>
          },
          renderCell: (params: any) => {
            return <strong style={{ color: formatterColorTemperatura(params.value) }}>{params.value ? params.value : '-'}</strong>
          }
        })
      } else if (v === 'Preco') {
        result.push({
          field: 'Preco',
          headerName: 'Valor',
          type: 'number',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          cellClassName: 'cell-class',
          valueFormatter: (params: GridValueFormatterParams<any>) => formatterPreco(params),
          renderCell: (params: any) => {
            return <>R$&nbsp;{params.formattedValue}</>
          },
          renderHeader: () => {
            return <strong>Patrimônio</strong>
          }
        })
      } else if (v === 'UltimaAtividade') {
        result.push({
          field: 'UltimaAtividade',
          headerName: 'Atividade',
          cellClassName: 'cell-class',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          valueFormatter: (params: GridValueFormatterParams<any>) => formatterAtividade(params),
          renderCell: (params: any) => {
            // Format value
            let lastActivityDay = Number(params.formattedValue)
            let textToShow = ''
            if (lastActivityDay == 0 || lastActivityDay == 1) {
              textToShow = lastActivityDay + ' dia'
            } else if (lastActivityDay > 1) {
              textToShow = lastActivityDay + ' dias'
            }


            // Insert the color
            let colorToText = ''

            if (lastActivityDay >= 0 && lastActivityDay < 5) {
              colorToText = '#b2b1ff'
            } else if (lastActivityDay < 10) {
              colorToText = '#ffae55'
            } else {
              colorToText = '#ffa4a4 '
            }

            return <>
              <span style={{ fontWeight: 'bold', color: colorToText }}>{textToShow}</span>
            </>
          },
          renderHeader: () => {
            return <strong>Última atualização</strong>
          }
        })
      } else if (v === 'PotencialDeInvestimento') {
        result.push({
          field: 'PotencialDeInvestimento',
          headerName: 'Potencial de Investimento',
          type: 'text',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          cellClassName: 'cell-class',
          renderHeader: () => {
            return <strong>Potencial de Investimento</strong>
          },
          renderCell: (params: any) => {
            return <span>{params.value ? params.value : '-'}</span>
          }
        })
      } else if (v === 'SubStatus') {
        result.push({
          field: 'SubStatus',
          headerName: 'Status',
          cellClassName: 'cell-class',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          renderHeader: () => {
            return <strong>Status</strong>
          },
          renderCell: (value: any) => {
            if (value.row.SubStatus != null && value.row.SubStatus != '') {
              return <>{value.row.SubStatus}</>
            } else {
              return <>Qualificação</>
            }
          }
        })
      } else if (v === 'CodigoCliente') {
        result.push({
          field: 'CodigoCliente',
          headerName: 'Código do Cliente',
          cellClassName: 'cell-class',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          renderHeader: () => {
            return <strong>Código do Cliente</strong>
          },
          renderCell: (params: any) => <span>{params.value ? params.value : '-'}</span>
        })
      } else if (v === 'TelefoneContatoPrimario') {
        result.push({
          field: 'TelefoneContatoPrimario',
          headerName: 'Telefone',
          cellClassName: 'cell-class',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          renderHeader: () => {
            return <strong>Contato</strong>
          },
          renderCell: (params: any) => <span>{params.value ? params.value : '-'}</span>
        })
      } else if (v === 'MotivoDePerda') {
        result.push({
          field: 'MotivoDePerda',
          headerName: 'Motivo de Perda',
          cellClassName: 'cell-class',
          headerAlign: 'center',
          align: 'center',
          flex: 1,
          renderHeader: () => {
            return <strong>Motivo de Perda</strong>
          },
          renderCell: (params: any) => <span>{params.value ? params.value : '-'}</span>
        })
      } else if (v === 'Acoes') {
        result.push({
          field: 'Acoes',
          headerName: 'Ações',
          headerAlign: 'center',
          align: 'center',
          width: 120,
          sortable: false,
          renderHeader: () => {
            return <strong>Ações</strong>
          },
          renderCell: (params: any) => {
            const client = params.row as interfaceClient;
            const canClose = isMonthlyClosureEnabled(client.UltimoFechamentoMensal);

            const handleClickFechamento = async (e: React.MouseEvent) => {
              e.stopPropagation();
              handleOpenDialog(params.row);
            }

            return (
              <div>
                {canClose ? (
                  <Tooltip title='Fechamento do mês' disableInteractive>
                    <IconButton onClick={handleClickFechamento} color='primary'>
                      <WorkOutlineIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip title='Fechamento deste mês já realizado' disableInteractive>
                    <span>
                      <IconButton disabled color='primary'>
                        <WorkOutlineIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </div>
            )
          },
        });
      }
    })

    return result;
  }, [columns])


  return <>
    <DataGrid
      sx={{
        fontSize: 15,
        border: 'none',
        '& .MuiDataGrid-cell': {
          borderBottom: '1px solid #d1aeed1f'
        },
        '& .MuiDataGrid-cell:focus': {
          outline: 'none'
        }
      }}

      onCellClick={(param) => {
        let url = `/app/perfil-cliente/${param.id}`
        window.open(url, '_blank', 'noopener,noreferrer');
      }}

      loading={loading}
      checkboxSelection={false}
      rows={clientes}
      columns={buildColumns}
      pageSize={20}
      rowsPerPageOptions={[20]}
      getRowId={(row: any) => row.Id}
      autoPageSize={true}
      autoHeight={true}
      localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth='xs' fullWidth>
      <DialogTitle variant='h3' sx={{ pt: 5, px: 5, pb: 2 }}>Confirmação</DialogTitle>
      <DialogContent sx={{ padding: 5 }}>
        <Divider sx={{ mb: 2 }} />
        <DialogContentText component={Typography} variant='body1' sx={{ mb: 2 }}>
          Insira uma descrição para a atividade de fechamento mensal:
        </DialogContentText>
        <TextField
          fullWidth
          label="Descrição"
          multiline
          rows={3}
          value={tempDescription}
          onChange={(event) => {
            const newValue = event.target.value;
            if (newValue.length <= 500) {
              setTempDescription(newValue);
            }
          }}
          sx={{ mb: 2 }}
          inputProps={{
            maxLength: 500,
          }}
          helperText={`${tempDescription.length}/500 caracteres restantes`}
          InputLabelProps={{ shrink: true }}
        />

        <Grid container spacing={2} justifyContent='flex-end' sx={{ mt: 3 }}>
          <Grid item>
            <Button onClick={handleCloseDialog} disabled={processing} color='secondary'>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={handleConfirmClosure}
              variant='contained'
              color='primary'
              disabled={processing}
              startIcon={processing && <CircularProgress size={20} />}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>

  </>
}

export default DataGridComponent;