import { IUser } from "./user";

export interface interfaceClient {
  Id?: string;
  NomeCompleto: string | null;
  CPF: string | null;
  DataNascimento: string | null;
  Email: string | null;
  CriadoData?: string | null;
  AposentadoriaAnos: number | null;
  EstadoCivil: string | null;
  Profissao: string | null;
  Sexo: string | null;
  TelefoneContatoPrimario: string | null;
  TelefoneContatoSecundario: string | null;
  Status: "LEAD" | "R1" | "OTHERS_MEETING" | "QUALIFICACAO" | "NEGOCIO" | "WAITING_CLIENT" | "CLIENTE" | "PERDEU" | "PAUSADO";
  AssessorUsuarioId: string | null;
  CriadoPor?: string | null;
  StatusSeguros?: "LEAD" | "R1" | "AGUARDANDO" | "FECHADO" | "EM_ANALISE" | "EMITIDO" | "CANCELADO" | "PERDEU";
  DataEmissaoSeguros?: Date | string;
  UsuarioIdSeguros?: string;
  UltimaAtividade: Date | string;
  Preco: number | null;
  CEP: string | null;
  Cidade: string | null;
  Estado: string | null;
  Bairro: string | null;
  Endereco: string | null;
  Numero: number | null;
  Complemento: string | null;
  PDFGerado: boolean | null;
  PossuiDependentes: boolean | null;
  PerfilInvestidor: string | null;
  MomentoDeVida: string | null;
  Relacionamento: string | null;
  Temperatura: string | null;
  PotencialDeInvestimento: string | null;
  Origem: string | null;
  CidadeNatal: string | null;
  PossuiAdministradoraBens: boolean | null;
  DescricaoAdministradoraDeBens: string | null;
  CodigoCliente: string | null;
  SubStatus: string | null;
  MotivoDeGanho: string | null;
  MotivoDePerda: string | null;
  MotivoDePausa: string | null;
  Bio: string | null;
  Idade: number | null;
  OrigemMarketing: true | null;
  FeeFixo: boolean;
  Insurance?: boolean;
  AutomatedWallet?: boolean;
  ManagersFunds?: boolean;
  CurrencyExchange?: boolean;
  OtherInvestment?: boolean;
  DataCadastro?: string;
  InteresseReuniaoSegurosDeVida?: boolean;
  InteresseReuniaoPlanoDeSaude?: boolean;
  InteresseReuniaoPlanejamentoPatrimonial?: boolean;
  InteresseReuniaoOutros?: boolean;
  PatrimonioEstimado?: number;
  PossuiSeguros?: boolean;
  ConfiguracaoFamiliar?: string;
  DescricaoSeguros?: string;
  MotivoRemocaoSeguros?: string;
  PASeguros?: number;
  FormaPagamentoSeguros?: string;
  ReguaRelacionamento?: boolean;
  UltimoFechamentoMensal?: Date | string;
}

export interface interfaceClientSeguros extends interfaceClient {
  AssessorInfos: IUser | null;
}

export interface IListServices {
  ClienteServicoId: string,
  ServicoId: string,
  Nome: string,
  DataAdesao: string | null,
  Tipo: 'step' | 'check',
  ClienteAtivo: boolean
}

export const ORIGINS = [
  { label: "Marketing", value: "Marketing" },
  { label: "Finder", value: "Indicação Finder" },
  { label: "Cliente", value: "Indicação Cliente" },
  { label: "Evento realizado", value: "Evento realizado" },
  { label: "Participação em meios sociais", value: "Participação em meios sociais" },
  { label: "Visita na sede", value: "Visita na sede" },
  { label: "Lista fria", value: "Lista fria" },
  { label: "Tombamento de conta", value: "Tombamento de conta" },
  { label: "RecomenDay", value: "RecomenDay" },
  { label: "Time de SDR's", value: "Time de SDR's" },
  { label: "Outros", value: "Outros" }
];